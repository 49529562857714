.Search {
  margin: 16px 0 !important;
}

.ButtonIcon {
  margin-right: 0.5rem;
}

.Button span {
  color: #fff;
  display: flex;
  align-items: center;
}

.UserDetail img {
  max-width: 350px;
}
