.Active {
  background: white;
  box-shadow: 0 12px 20px -10px #1470af, 0 4px 20px 0px rgba(0, 0, 0, 0.12),
    0 7px 8px -5px rgba(0, 0, 0, 0.12);
  transition: all 100ms linear;
  border-radius: 3px;
  width: 90%;
  margin: 0 auto;
  color: #1470af !important;
}

.Active .ItemText {
  color: #1470af !important;
  padding: 0 !important;
}

.Active svg {
  color: #1470af !important;
}
