.CalendarContainer {
  margin: 1rem auto;
  width: 100%;
}

.CalendarButtons {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
}

.CalendarButtons button:not(:last-child) {
  margin-right: 1rem;
}

.Calendar {
  height: 500px;
}

.Input {
  width: 100%;
}

.Button {
  margin: 0px auto 25px auto !important;
}
