body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background: #eee;
}

.primaryColor {
  background: #1470af !important;
}

.primaryColorText {
  color: #1470af !important;
}
