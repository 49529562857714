.Title {
  font-size: 2rem;
  margin-top: 48px;
}

.Button {
  margin: 0px auto 25px auto !important;
}

.ErrorMessage {
  color: red;
  font-size: 16px;
  padding: 16px;
}

.Input {
  width: 100%;
}

.Card {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 1400px !important;
}
