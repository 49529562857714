div.PrimaryColorHeader {
  padding: 0 !important;
}

.Dashboard a {
  text-decoration: none;
}

@media (max-width: 960px) {
  .Dashboard {
    margin-top: 65px;
  }
}
