
.TextField, .Textarea {
  width: 90%;
}

.Input {
  width: 100%;
}

.Recaptcha {
width: 98%;
margin: 0 auto;
margin-top: 15px;
}

.Dense {
margin-top: 16px !important;
}

.Textarea {
width: 90%;
}

.Checkbox {
margin-left: 5% !important;
}

.SelectContainer {
width: 80%; 
}

.Invalid {
border: 1px solid red;
background-color: salmon;
}

.ValidationError {
color: red;
margin: 5px 0;
}

.OutlinedSelect {
  width: 100% !important;
}

@media (min-width: 960px) {
.TextField {
  width: 80%;
}

.Recaptcha {
  width: 80%;
}
}