.Auth {
  width: 100%;
  height: 100vh;
}
.AuthFormContainer {
  display: table;
  width: 100%;
  height: 100%;
}

.AuthFormSubContainer {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.AuthForm {
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  background: white;
  padding: 40px;
  text-align: center;
}

.InputContainer {
  width: 80%;
  margin: 0 auto;
}

.SubmitButton {
  padding: 10px 40px !important;
  margin-top: 40px !important;
}

.ErrorMessage {
  color: red;
}

.Logo {
  width: 300px;
  margin: -30px auto 30px auto;
}

@media (min-width: 960px) {
  .AuthForm {
    max-width: 40%;
  }
}
