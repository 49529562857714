.Input {
  width: 100%;
}

.InputOutline {
  border-color: #1470af !important;
}

.InputLabel {
  color: #1470af !important;
}
