.Search {
  margin: 16px 0 !important;
}

.ButtonIcon {
  vertical-align: middle;
}

.Button {
  margin-left: 1rem;
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
}

.Button span {
  display: none;
  vertical-align: middle;
}

@media (min-width: 500px) {
  .Button span {
    display: inline-block;
  }
}
