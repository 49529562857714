.Title {
  font-size: 2rem;
  margin-top: 48px;
}

.Button {
  margin: 0px auto 25px auto !important;
}

.ErrorMessage {
  color: red;
  font-size: 16px;
  padding: 16px;
}

.Input {
  width: 100%;
}

.Paper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px;
}

.Chip {
  margin: 8px;
}

.EntityPicker {
  max-width: 500px;
  margin: auto !important;
}
